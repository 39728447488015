import React from "react"
import { graphql } from "gatsby"
import statWinner from "../../js/statWinner"
//import formatNumComma from "../../js/formatNumComma"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DetailedStatBars from "../../components/DetailedStats/DetailedStatBars"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import InfoBar from '../../components/Utilities/InfoBar'

class AverageMatchRatings extends React.Component {

  render() {

    const pageTitle = "Average Match Ratings"

    const mdata = this.props.data.allSheetMessiClubStats.edges
    const rdata = this.props.data.allSheetRonaldoClubStats.edges

    const subStat = (x, comp1, comp2) => {
      return x === "m" ? (
        mdata.filter(d => d.node.competition !== comp1).filter(d => d.node.competition !== comp2).map(d => d.node.avgRating ? d.node.avgRating : '').filter(Boolean)
      ) : (
        rdata.filter(d => d.node.competition !== comp1).filter(d => d.node.competition !== comp2).map(d => d.node.avgRating ? d.node.avgRating : '').filter(Boolean)
      )
    }

    //const subStatAll = mdata.filter(d => d.node.competition !== "League").map(d => d.node.avgRating ? d.node.avgRating : '').filter(Boolean) + mdata.filter(d => d.node.competition !== "Champions League").map(d => d.node.avgRating ? d.node.avgRating : '').filter(Boolean)

    const comps = [
      {
        "comp": "League + Champs League",
        "mSubStat": subStat('m', 'All Competitions', 'All Competitions'),
        "rSubStat": subStat('r', 'All Competitions', 'All Competitions'),
        "btnText": "Season Breakdown"
      },
      {
        "comp": "League Since 09",
        "mSubStat": subStat('m', 'All Competitions', 'Champions League'),
        "rSubStat": subStat('r', 'All Competitions', 'Champions League'),
        "btnText": "Season Breakdown",
        "title": "League"
      },
      {
        "comp": "Europe Since 09",
        "mSubStat": subStat('m', 'All Competitions', 'League'),
        "rSubStat": subStat('r', 'All Competitions', 'League'),
        "btnText": "Season Breakdown"
      },
    ]

    return (

      <Layout>
        <SEO
          title={`${pageTitle} - Messi and Ronaldo Player Ratings`}
          description={`Their goal tallies are well documented, but what about Messi and Ronaldo's all-round performances? Luckily for you, we have all their average match rating stats for you to compare.`}
          canonicalPath={`/detailed-stats/average-match-ratings/`}
        />

        <h1>{pageTitle} <span className="sr-only">- Messi and Ronaldo</span></h1>

        <div className="flex items-center justify-center mt-8 lg:mt-10 xl:mt-12 max-w-xs mb-0 mx-auto px-4">
          <InfoBar classes="w-full justify-center" msg="Non-European league stats not included in totals" />
        </div>

        <div className="w-full p-4 max-w-screen-2xs mx-auto">
          <InfoBar classes="w-full justify-center" msg="Based on WhoScored match ratings" />
        </div>

        <div className={`max-w-lg mx-auto flex flex-wrap justify-center`}>

          {comps.map(d => (
            <DetailedStatBars
              key={d.comp}
              data={this.props.data}
              competition={d.comp}
              playerTitleHidden={`${d.comp} ${pageTitle} since 09/10`}
              statType={pageTitle}
              btnText={d.btnText}
              mSubStat={`averaged > 8.0 in ${d.mSubStat.map(d => d > 8).filter(Boolean).length}/${d.mSubStat.length} comps`}
              rSubStat={`averaged > 8.0 in ${d.rSubStat.map(d => d > 8).filter(Boolean).length}/${d.rSubStat.length} comps`}
              subStatW={statWinner(d.mSubStat.map(d => d > 8).filter(Boolean).length, d.rSubStat.map(d => d > 8).filter(Boolean).length)}
              since="09/10"
              title={d.title}
            />
          ))}

        </div>

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          competition
          apps
          minsPlayed
          avgRating
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          competition
          apps
          minsPlayed
          avgRating
        }
      }
    }
    allSheetMessiClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          avgRating
        }
      }
    }
    allSheetRonaldoClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          avgRating
        }
      }
    }
    allSheetMessiIntStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          avgRating
        }
      }
    }
    allSheetRonaldoIntStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          avgRating
        }
      }
    }
  }
`

export default AverageMatchRatings
